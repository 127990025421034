const logging = require('logging');
const _ = require('underscore');
const $os = require('detectOS');
const Backbone = require('Backbone');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const KeyCode = require('@common/data/enums/KeyCode');

const GameChallengesList = require('@training/apps/training/collections/GameChallengesList');
const GameChallengeItemView = require('@training/apps/training/views/GameChallengeItemView');
const { GameItemCard } = require('@training/apps/training/views/GameItem');

const SelectGameHowToModal = require('@training/apps/training/views/SelectGameHowToModal');

require('@common/libs/behaviors/card/Card');
const { INTERACTIVE } = require('@common/libs/behaviors/card/CardModifierClasses');

class SelectGamePage extends UIKit.View {
  behaviors() {
    return {
      Card: {
        target: '.skip-game, .featured .game',
        modifierClasses: [INTERACTIVE]
      }
    };
  }

  events() {
    return {
      'click .skip-game': 'skipGameClick',
      'click .challenges .challenge-cell': 'selectGameClick',
      'click .featured .game': 'selectGameClick',
      'click .recent .game': 'selectGameClick',
      'click .popular .game': 'selectGameClick',
      'click .all-games .game': 'selectGameClick',
      'keypress .game': 'onKeyPressGame'
    };
  }

  className() {
    return 'select-game-page ax-container';
  }

  initialize(options = {}) {
    this.template = _.tpl(require('@training/apps/training/templates/SelectGamePage.html'));

    this.selectGameModalStatus = options.selectGameModalStatus;

    this.gameChallengesList = new GameChallengesList([], {
      allSupportedGames: this.model.getAllSupportedGames(),
      maxChallenges: 5
    });

    this.gameChallengesListPromise = this.gameChallengesList.fetch();

    this.listenTo(this.gameChallengesList, 'remove', () => {
      this.gameChallengesList.fetch().always(() => {
        if (!this.gameChallengesList.length) {
          this.$('.challenges').hide();
        }
      });
    });
  }

  selectGameClick(e) {
    if (apps.base.checkDoubleSubmit()) {
      return false;
    }

    // `currentTarget.activeElement` is for keyboard navigation
    let $target = $(e.currentTarget.activeElement != null ? e.currentTarget.activeElement : e.currentTarget);
    // Dig down into the `.game` element because `.challenge-cell` has the click
    // handler that calls this method but doesn't contain the data attributes
    if (!$target.hasClass('game')) {
      $target = $target.find('.game');
    }
    const gameId = $target.data('gameId');
    const challengeId = $target.data('challengeId');

    if (gameId !== undefined) {
      return this.selectGame(gameId, challengeId);
    }
  }

  selectGame(gameId, challengeId) {
    if (this.gameSelected) {
      return;
    }
    this.gameSelected = true;

    logging.info(`Selecting game: ${ gameId }`);

    if (gameId != null) {
      if (challengeId != null) {
        return Backbone.history.navigate(`#games/${ gameId }/challenge/${ challengeId }`, {trigger: true});
      }
      return Backbone.history.navigate(`#games/${ gameId }`, {trigger: true});

    }
    // gameId is `null` so do just questions
    return this.options.selectGame(gameId);

  }

  render() {
    const featuredGame = this.model.getFeaturedGame();

    if (featuredGame != null) {
      // Load view
      this.$el.html(this.template({featuredGame: featuredGame.toJSON()}));
    }

    return this;
  }

  viewDidAppear() {
    logging.info('SelectGamePage - viewDidAppear');

    this.gameSelected = false;

    window.app.layout.setTitle(I18n.t('selectGame.pageTitle'));

    if (this.model.getAllSupportedGames().length) {
      this._renderGameLists();

      if (this.selectGameModalStatus.get('modalViewCount') === 0) {
        SelectGameHowToModal.displayModal(app.layout);

        this.selectGameModalStatus.setModalAsViewed();
      }
    } else {
      logging.debug('Auto selecting game');
      this.selectGame(null);
    }
  }

  _renderGameLists() {
    const ListViewClass = $os.mobile || $os.tablet ? UIKit.ScrollingListView : UIKit.ListView;

    this.gameChallengesListPromise.done(() => {
      if (this.gameChallengesList.length) {
        const challengesGamesListView = new ListViewClass({
          collection: this.gameChallengesList,
          ModelView: GameChallengeItemView
        });
        this.setSubviewIn(challengesGamesListView, {
          regionSelector: '.challenges .gameslist',
          transition: UIKit.View.Transitions.NONE
        });
        return challengesGamesListView.renderList();
      }
      return this.$('.challenges').hide();

    });

    const recent = this.model.getRecentGames(5);
    if (recent.length) {
      const recentGamesListView = new ListViewClass({
        collection: recent,
        ModelView: GameItemCard
      });
      this.setSubviewIn(recentGamesListView, {
        regionSelector: '.recent .gameslist',
        transition: UIKit.View.Transitions.NONE
      });
      recentGamesListView.renderList();
    } else {
      this.$('.recent').hide();
    }

    const popular = this.model.getPopularGames(5);
    if (popular.length) {
      const popularGamesListView = new ListViewClass({
        collection: popular,
        ModelView: GameItemCard
      });
      this.setSubviewIn(popularGamesListView, {
        regionSelector: '.popular .gameslist',
        transition: UIKit.View.Transitions.NONE
      });
      popularGamesListView.renderList();
    } else {
      this.$('.popular').hide();
    }

    const allGamesListView = new UIKit.ListView({
      collection: this.model.getAllSupportedGames(),
      ModelView: GameItemCard.extend({
        gridFullWidthMobile: true
      })
    });
    this.setSubviewIn(allGamesListView, {
      regionSelector: '.all-games .gameslist',
      transition: UIKit.View.Transitions.NONE
    });
    return allGamesListView.renderList();
  }

  skipGameClick() {
    return this.selectGame(null);
  }

  onKeyPressGame(e) {
    if ([KeyCode.ENTER, KeyCode.SPACE].includes(e.which)) {
      e.preventDefault(); // stops the page scroll when spacebar is pressed
      return this.selectGameClick(e);
    }
  }
}

module.exports = SelectGamePage;

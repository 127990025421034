const { guardMethod } = require('DecaffeinateHelpers');

const $os = require('detectOS');
const logging = require('logging');
const _ = require('underscore');
const env = require('env');
const {
  sendWarnLog,
  sendLog
} = require('LoggingService');

const I18n = require('@common/libs/I18n');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const VideoPlayer = require('@common/components/video/players/VideoPlayer');

require('@common/components/video/players/transcoded/Transcoded.less');
require('@common/components/video/players/transcoded/PlayerControl.less');

const VideoTypes = {
  dashplaylist: 'application/dash+xml',
  hlsplaylist: 'application/x-mpegURL',
  mp4: 'video/mp4',
  ogv: 'video/ogg'
};

class TranscodedPlayer extends VideoPlayer {
  constructor(options = {}) {
    super(options);
    this._compileSource = this._compileSource.bind(this);
    this._compileTrack = this._compileTrack.bind(this);
    this._onPlayerSuccess = this._onPlayerSuccess.bind(this);
    this._triggerVideoSeek = this._triggerVideoSeek.bind(this);
    this._onLoadedMetaData = this._onLoadedMetaData.bind(this);
    this._preventSeekForward = this._preventSeekForward.bind(this);
    this._logOnQualityChange = this._logOnQualityChange.bind(this);
    this._onPlayerError = this._onPlayerError.bind(this);
    this.exitFullScreen = this.exitFullScreen.bind(this);
    this._preventSeekForwardForNative = this._preventSeekForwardForNative.bind(this);

    const title = this.model.getMediaOriginalFileName() || '';

    this.template = _.tpl(`
      <video class="mejs-video" oncontextmenu="return false;" title="${ title }" width="<%= width %>" height="<%= height %>" preload="<%= preload %>" controls>
        <%= sources %>
        <%= tracks %>
      </video>\
    `);
    this.emptyTranscodingTemplate = _.tpl(`\
      <div class="empty-transcodings">
        <div><%= t('video.emptyTranscodingsProcessing') %></div>
        <div class="semiboldfont"><%= t('video.emptyTranscodingsReturn') %></div>
      </div>\
    `);
    this.failedTranscodingTemplate = _.tpl(`\
      <div class="empty-transcodings">
        <div><%= t('video.failedTranscodingsProcessing') %></div>
        <div class="semiboldfont"><%= t('video.failedTranscodingsReturn') %></div>
      </div>\
    `);
    this.sourceElementTemplate = _.tpl('<source src="<%= url %>" type="<%= videoType %>" />');
    this.trackElementTemplate = _.tpl(`\
      <track
      enabled="true"
      kind="subtitles"
      label="<%= languageLabel %>"
      srclang="<%= language %>"
      type="text/vtt"
      src="<%= '/axonify/' + rootPath + '/' + uuid + '/cc/' + language + '.vtt' %>"
      />\
    `);
    this.defaultErrorTemplate = _.tpl(`\
      <p class="video-error">
        <%= t('video.defaultError') %>
      </p>\
    `);

    ({
      poster: this.poster,
      userLanguage: this.userLanguage,
      preventSeekForward: this.preventSeekForward = false,
      maxTimeViewed: this.maxTimeViewed = 0
    } = options);

    if (!this.poster) {
      this.poster = this.model.getMediaPoster();
    }
  }

  render() {
    if (this.model.isMediaTranscodingAvailable()) {
      logging.debug(`Video Transcodings: ${ JSON.stringify(this.model.getMediaTranscoding()) }`);
      super.render();
    } else if (this.model.isMediaTranscodingFailed()) {
      this._renderFailedTranscoding();
    } else {
      this._renderEmptyTranscoding();
    }
  }

  getTemplateOptions() {
    return $.extend(true, super.getTemplateOptions(), {
      preload: 'metadata',
      sources: this._compileSources(),
      tracks: this._compileTracks()
    });
  }

  renderPlayer() {
    this.$video = this.$('video');
    const player = this._getPlayer();

    return player.then(() => {
      logging.debug(`Has mediaJS loaded? ${ (typeof mejs !== 'undefined' && mejs !== null) }`);
      if (this.isDestroyed) {
        return;
      }

      this._renderPlayer();
      //We need this resize for older FF such as version 38
      BrowserHelpers.triggerResize(true);
    });
  }

  _getPlayer() {
    return import('@common/vendor/mediaelement/v4/mediaelement-and-player.js');
  }

  removePlayer() {
    if (this._mediaElement != null) {
      this._mediaElement.removeEventListener('ended', this._triggerEnd, false);
      this._mediaElement.removeEventListener('play', this._triggerPlay, false);
      this._mediaElement.removeEventListener('pause', this._triggerPause, false);
      this._mediaElement.removeEventListener('seeking', this._triggerVideoSeek);
      this._mediaElement.removeEventListener('timeupdate', this._triggerTimeUpdate);
      this._mediaElement.removeEventListener('canplay', this._triggerCanPlay);
      this._mediaElement.removeEventListener('loadedmetadata', this._onLoadedMetaData);
    }

    this._removePreventingSeekForward(this._mediaElement);

    // remove the focusin hack working around a mediaelement.js issue
    this.$('.mejs-captions-button').off('touchstart', this.onPressCaptionsSelectorButton);


    // TODO: Disabled MPEG-DASH logging as a possible solution for large logging post in NR Grab graphs for now.
    // Remove the logging code if it solves the issue.
    //this._removeQualityChangedRendered(this._mediaElement);

    this._removeOriginalVideoTag();
    this._removeMediaElement();

    this.vid = null;
    return (this._mediaElement = null);
  }

  _removePreventingSeekForward(_mediaElement) {
    this._mediaElement = _mediaElement;
    if (this.isLoaded()) {
      this._mediaElement.removeEventListener('loadstart', this._preventSeekForward, false);
    }
  }

  _removeQualityChangedRendered(_mediaElement) {
    this._mediaElement = _mediaElement;
    if (this.isLoaded()) {
      this._mediaElement.removeEventListener('loadedmetadata', this._logOnQualityChange, false);
    }
  }

  _removeOriginalVideoTag() {
    if (this.$video != null) {
      this.$video.empty();
      this.$video.removeAttr('src');
      this.$video.on('load');
    }
  }

  _removeMediaElement() {
    // TODO: the `try` block is to remove the MediaElementPlayer instance
    // which works for HTML5 players but throws an exception when trying to
    // remove the Flash `object` in IE which is done in the `catch` block
    // by calling `remove` on the MediaElement instance.
    try {
      // We need to add this line since Library has known issue with setSrc() and remove() methods and they fix in latest V4.1.
      if (this.vid != null) {
        this.vid.options.error = null;

        if (!this.vid.paused) {
          this.vid.pause();
        }

        // Empty out the renderer's source elements so they don't trigger a data load when the video is removed.
        this.vid.media.renderer.empty();
        this.vid.remove();
      }
    } catch (error) {
      // IE doesn't have the remove method so we fallback to removeNode
      try {
        if (guardMethod(this._mediaElement, 'remove', (o) => {
          return o.remove();
        }) == null) {
          guardMethod(this._mediaElement, 'removeNode', (o1) => {
            return o1.removeNode();
          });
        }
      } catch (error1) {
        logging.debug(error1);
      }
    }
  }

  resize() {
    super.resize();

    // If the player is fullscreen, there is no need to reflow it
    if (this.vid != null ? this.vid.isFullScreen : undefined) {
      return;
    }

    const width = this._getWidth();
    const height = this._getHeight();

    // The sequence to resize an instance of a `MediaElement` player is
    // `setPlayerSize`, `setControlsSize`, and `setVideoSize`
    try {
      if (this.vid != null) {
        this.vid.setPlayerSize(width, height);
      }
    } catch (error) {
      if (this.$video != null) {
        this.$video.attr({
          width,
          height
        });
      }
    }

    if (this.vid != null && this.vid.container != null) {
      this.vid.setControlsSize();
    }
    this._setVideoSize(width, height);
  }

  play() {
    if (this.isLoaded()) {
      this._mediaElement.play();
    }
  }

  pause() {
    if (this.isLoaded()) {
      this._mediaElement.pause();
    }
  }

  getDuration() {
    if (this.isLoaded()) {
      return this._mediaElement.duration;
    }

    return super.getDuration();
  }

  getCurrentTime() {
    if (this.isLoaded()) {
      return this._mediaElement.currentTime;
    }

    return super.getCurrentTime();
  }

  setStartTime(time) {
    this.startTime = time;
  }

  setCurrentTime(time) {
    if (this.isLoaded()) {
      this._mediaElement.currentTime = time;
    }
  }

  isLoaded() {
    return this._mediaElement != null;
  }

  isPlaying() {
    if (this.isLoaded()) {
      return !this._mediaElement.paused;
    }

    return super.isPlaying();
  }

  isEnded() {
    return this._mediaElement.ended;
  }

  isSeeking() {
    if (this.isLoaded()) {
      return this._mediaElement.seeking;
    }

    return false;
  }

  _setVideoSize(width, height) {
    if (this.vid) {
      guardMethod(this.vid.media, 'setSize', (o) => {
        return o.setSize(width, height);
      });
    }
  }

  _compileSources() {
    const availableFormatList = this._sortAvailableFormatList();

    return _.reduce(availableFormatList, this._compileSource, '');
  }

  getVideoTypeOrdering() {
    const DEFAULT_VIDEO_TYPE_ORDERING = ['dashplaylist', 'hlsplaylist', 'mp4', 'ogv'];

    if ($os.ios || $os.browser === 'safari') {
      // for iOS, we prefer HLS if it's available, but we will try others if not available
      return ['hlsplaylist', 'dashplaylist', 'mp4', 'ogv'];
    }

    if (($os.android && $os.browser === 'ucbrowser') || ($os.browser === 'firefox' && (($os.version >= 45 && $os.version <= 47) || $os.version >= 118))) {
      // for UC Browser, we only want HLS if it's available (no MPEG-DASH), but we will try others if not available
      // for FF45 MPEG-DASH causes timeline crashes when the user tries to create a new post or opens the timeline page with posts with linked videos.
      return ['hlsplaylist', 'mp4', 'ogv'];
    }

    if ($os.browser === 'chrome' && $os.version === 91) {
      return ['ogv'];
    }

    return DEFAULT_VIDEO_TYPE_ORDERING;
  }

  _sortAvailableFormatList() {
    // Dash.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
    // When the browser has built-in HLS support (such as IOS), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
    // This is using the built-in support of the plain video element. To do that we need to sort sources and hls manifest should be the very first one.
    let availableFormatList = this.model.getMediaFormats();

    const ordering = this.getVideoTypeOrdering();

    // First filter out any unsupported video formats (e.g. for UC Browser)
    availableFormatList = _.filter(availableFormatList, (format) => {
      return _.contains(ordering, format.type);
    });

    return (availableFormatList = _.sortBy(availableFormatList, (format) => {
      return _.indexOf(ordering, format.type);
    }));
  }

  _compileSource(memo, format) {
    const data = this._getSourceTemplateData(format);
    const src = memo + this.sourceElementTemplate(data);
    return src;
  }

  _getSourceTemplateData(format) {
    let path, preload;
    if (format.type === 'dashplaylist') {
      path = format.path + '/manifest.mpd';
      preload = 'auto';
    } else if (format.type === 'hlsplaylist') {
      path = format.path + '/playlist.m3u8';
      preload = 'none';
    } else {
      preload = 'metadata';
      (({
        path
      } = format.file));
    }

    return {
      url: path,
      preload,
      videoType: VideoTypes[format.type]
    };
  }

  _compileTracks() {
    return _.reduce(this.model.getMediaClosedCaptions(), this._compileTrack, '');
  }

  _compileTrack(memo, caption) {
    let track = memo;
    if (caption.status === 'done') {
      const data = this._getTrackTemplateData(caption);
      track = memo + this.trackElementTemplate(data);
    }
    return track;
  }

  _getTrackTemplateData(caption) {
    let left;
    return {
      uuid: this.model.getMedia().uuid,
      language: caption.language,
      languageLabel: I18n.languageNameFromCurrentLocale(caption.language),
      rootPath: (left = this.model.getMedia().rootClosedCaptionLocation) != null ? left : 'media/video'
    };
  }

  _renderEmptyTranscoding() {
    this.$el.html(this.emptyTranscodingTemplate());
  }

  _renderFailedTranscoding() {
    this.$el.html(this.failedTranscodingTemplate());
  }

  _renderPlayer() {
    if ($os.ios && this.preventSeekForward) {
      this._addPreventingSeekForwardForNative();
    }

    try {
      this.vid = new MediaElementPlayer(this.$video[0], this._getMediaElementPlayerOptions());
    } catch (error) {
      logging.error('Failed to play the video (VideoPlayer.render), skipping');
      logging.error(`${ error }`);
      this._triggerError();
    }
  }

  _getMediaElementPlayerOptions() {
    const features = ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume', 'fullscreen'];

    const options = {
      enableKeyboard: true,
      features,
      startVolume: 1.0,
      customError: this.defaultErrorTemplate(),
      startLanguage: this._getStartLanguage(),
      plugins: ['flash'],
      poster: this._getVideoPoster(),
      success: this._onPlayerSuccess,
      error: this._onPlayerError
    };

    //Specific fix for festfoods, since their mobile devices do not have physical volume buttons
    if ($os.elo) {
      options.hideVolumeOnTouchDevices = false;
    }

    //Subtitles and full screen don't work in the iPad native app, and the option causes issues on Safari.
    //We implemented specific conditions for the iPad mobile app
    if ($os.ipad && $os.isInMobileApp()) {
      options.iPadUseNativeControls = true;
    }

    return this._addMediaElementPlayerVersionSpecificOptions(options);
  }

  _addMediaElementPlayerVersionSpecificOptions(options) {
    options.pluginPath = `${ env.settings.baseScriptURL }common/vendor/mediaelement/v4/`;
    options.classPrefix = 'mejs-';
    options.renderers = ['native_dash', 'flash_dash', 'native_hls', 'flash_hls', 'html5'];
    options.dash = {
      path: `${ env.settings.baseScriptURL }common/vendor/mediaelement/v4/dash.mediaplayer.min.js`
    };

    options.hls = {
      path: `${ env.settings.baseScriptURL }common/vendor/mediaelement/v4/hls.min.js`
    };

    return options;
  }

  _getStartLanguage() {
    // Set the `startLanguage` for the `tracks` to the `userLanguage` option
    // if the video is not in the user's language
    if (
      this.userLanguage != null
      && this.model.getMedia().language != null
      && this.userLanguage !== this.model.getMedia().language
    ) {
      // Interop with 3rd party library, so lower case is required
      return this.userLanguage.toLowerCase();
    }

    return '';
  }

  _getVideoPoster() {
    if (this.poster != null) {
      return this.poster;
    }

    return '';
  }

  _onPlayerSuccess(_mediaElement) {
    this._mediaElement = _mediaElement;
    if (this.isDestroyed) {
      return;
    }
    logging.debug('MediaElement success');

    // FastClick on Android (likely other mobile) wouldn't trigger the
    // `focusin` event required to display the Closed Caption track list.
    // so we're going to force it.
    this.$('.mejs-captions-button').on('touchstart', this.onPressCaptionsSelectorButton);


    this._mediaElement.addEventListener('ended', this._triggerEnd, false);
    this._mediaElement.addEventListener('play', this._triggerPlay, false);
    this._mediaElement.addEventListener('pause', this._triggerPause, false);
    this._mediaElement.addEventListener('seeking', this._triggerVideoSeek);
    this._mediaElement.addEventListener('timeupdate', this._triggerTimeUpdate);
    this._mediaElement.addEventListener('canplay', this._triggerCanPlay);
    this._mediaElement.addEventListener('loadedmetadata', this._onLoadedMetaData);

    // We should store current setCurrentTime method temporarily
    this.cachedCurrentTime = this._mediaElement.setCurrentTime.bind(this._mediaElement);

    let lastStalledTime = this._mediaElement.currentTime;
    let counter = 0;
    let failedCounter = 0;

    this.stallToken = setInterval(() => {
      // If for some reason this is nulled out or cannot be read
      if (!this._mediaElement || this.isDestroyed) {
        clearInterval(this.stallToken);

        logging.error('TranscodedPlayer - For some reason, the polling for stall checking is still running despite the media player no longer being valid');
        return;
      }

      // This is only needed on iOS since there is a network bug in iOS 10.2 where stalled streams
      // won't ever recover, even if the network comes back.
      // Thanks Apple. We'll ignore it for the rest of the operating systems that work fine.
      // Perhaps at some point media element will handle this.
      if ($os.ios) {
        if (this.isEnded()) {
          return;
        }

        if (this.isPlaying()) {
          const isStalled = this._mediaElement.currentTime === lastStalledTime;
          lastStalledTime = this._mediaElement.currentTime;

          if (isStalled) {
            counter++;
          } else {
            counter = 0;
          }
          if (counter > 10) {
            counter = 0;
            failedCounter++;

            if (failedCounter === 1) {
              this.attemptStallRecovery();
            } else if (failedCounter === 2) {
              this.triggerStalled();
            } else if (failedCounter === 3) {
              this.terminatePlayer();
            }
          }
        }
      }
    }, 1000);

    this._addPreventingSeekForward(this._mediaElement);

    // TODO: Disabled MPEG-DASH logging as a possible solution for large logging post in NR Grab graphs for now.
    // Remove the logging code if it solves the issue.
    //this._addQualityChangedRendered(this._mediaElement);

    this._triggerLoad();
    BrowserHelpers.triggerResize(true);

    if (this.autoplay) {
      this._mediaElement.play();
    }
  }

  terminatePlayer() {
    this.destroy();

    sendWarnLog({
      logData: {
        logMessage: 'The video stalled out and could not recover despite the handler attempting to. The user is now going to be notified: ',
        logs: false
      }
    });
    logging.warn(I18n.t('errors.timeout'));
  }

  attemptStallRecovery() {
    this.exitFullScreen();

    const currentTime = this._mediaElement.currentTime;

    const sleep = (n = 1250) => {
      return new Promise((resolve) => {
        setTimeout(resolve, n);
      });
    };

    // Reload the video, and force a play
    this.$el.hide();
    this.vid.load();

    const checkError = () => {
      const currentError = this._mediaElement.error;
      if (currentError) {
        this.terminatePlayer();
        return true;
      }

      return false;
    };

    sleep(1000).then(() => {
      if (checkError() || this.isDestroyed) {
        return;
      }

      this.vid.play();
    })
      .then(() => {
        if (!this.isDestroyed) {
          this.setCurrentTime(currentTime);
          this.$el.show();
        }
      });
  }

  triggerStalled() {
    this.trigger('player:stalled', this._mediaElement.currentTime);
  }

  exitFullScreen() {
    if ($os.ios) {
      this.$video[0].webkitExitFullScreen();
    } else if (BrowserHelpers.isFullScreen()) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (this.vid != null) {
        this.vid.exitFullScreen();
      }
    }
  }

  resumePlayback() {
    if (this.startTime) {
      this.setCurrentTime(this.startTime);
      delete this.startTime;
    }
  }

  _onLoadedMetaData() {
    if (!$os.ios) {
      this.resumePlayback();
    }
  }

  _triggerCanPlay(...args) {
    if ($os.ios) {
      this.resumePlayback();
    }

    super._triggerCanPlay(...args);
  }

  _triggerEnd(...args) {
    this.exitFullScreen();
    super._triggerEnd(...args);
  }

  _triggerVideoSeek(...args) {
    this.triggerMethod('video:seek', ...args);
  }

  _addPreventingSeekForward(_mediaElement) {
    this._mediaElement = _mediaElement;
    this._mediaElement.addEventListener('loadstart', this._preventSeekForward, false);
  }

  _preventSeekForward() {
    if (!this.preventSeekForward) {
      return;
    }

    // Overrride method
    this._mediaElement.setCurrentTime = (time) => {
      if (this.maxTimeViewed < this._mediaElement.currentTime) {
        this.maxTimeViewed = this._mediaElement.currentTime;
      }

      if (time <= this.maxTimeViewed) {
        this.cachedCurrentTime.call(this._mediaElement, time);
      }
    };
  }

  _addPreventingSeekForwardForNative() {
    this.videoElements = this.$('video');

    _.each(this.videoElements, (video) => {
      video.addEventListener('loadeddata', this._preventSeekForwardForNative, false);
      video.addEventListener('enterpictureinpicture', this._waitAndExitPictureInPicture, false);
    });
  }

  // On iOS, users can skip videos when in picture in picture (pip) mode
  // We close pip to prevent that when "Video Seek in Modules" is disabled
  _waitAndExitPictureInPicture() {
    setTimeout(() => {
      if (this.isDestroyed) {
        return;
      }
      document.exitPictureInPicture();
    }, 500);
  }

  _preventSeekForwardForNative(e) {
    let supposedCurrentTime = 0;
    const video = e.target;
    video.addEventListener('timeupdate', () => {
      if (!video.seeking) {
        if (this.maxTimeViewed < supposedCurrentTime) {
          this.maxTimeViewed = supposedCurrentTime;
        }

        supposedCurrentTime = video.currentTime;
      }
    });
    video.addEventListener('seeking', () => {
      const delta = video.currentTime - this.maxTimeViewed;
      if (delta > 0.01) {
        video.currentTime = supposedCurrentTime;
      }
    });
    video.addEventListener('ended', () => {
      supposedCurrentTime = 0;
    });
  }

  _addQualityChangedRendered(_mediaElement) {
    this._mediaElement = _mediaElement;
    this._mediaElement.addEventListener('loadedmetadata', this._logOnQualityChange, false);
  }

  _logOnQualityChange() {
    const dashPlayer = this._mediaElement.dashPlayer;
    const handleError = this._handleQualityError;

    const prepareAndSendLogMessage = function(videoObj, fragmentInfo) {
      let directionStr = 'Upsampled';
      if (!isNaN(videoObj.oldQuality) && videoObj.oldQuality > videoObj.newQuality) {
        directionStr = 'Downsampled';
      }

      const newFragmentInfo = _.map(JSON.parse(JSON.stringify(fragmentInfo)), (item) => {
        delete item.request.mediaInfo;
        delete item.request.mediaType;
        return item;
      });

      const bitrates = dashPlayer.getBitrateInfoListFor('video'),
        currenTime = dashPlayer.time(),
        manifestBitrates = [],
        newQuality = !isNaN(videoObj.newQuality) ? bitrates[videoObj.newQuality].bitrate / 1000 : 'undefined';

      _.forEach(bitrates, (e) => {
        manifestBitrates.push(e.bitrate / 1000);
      });

      const msg = `
        Transcoded player quality for ${ dashPlayer.getSource() } has ${ directionStr } :
        currentTime = ${ JSON.stringify(currenTime) },
        CurrentQuality = ${ JSON.stringify(newQuality) },
        Available Qualities = ${ JSON.stringify(manifestBitrates) },
        FragmentInfo = ${ JSON.stringify(newFragmentInfo) }
        `;


      handleError(msg);
    };

    if (dashPlayer) {
      const fragmentInfo = [];
      dashPlayer.setScheduleWhilePaused(false);
      dashPlayer.setFastSwitchEnabled(true);

      dashPlayer.on('fragmentLoadingStarted', (videoObj) => {
        if (videoObj.request.mediaType === 'video') {
          fragmentInfo.push(videoObj);
        }
      });

      ['qualityChangeRendered', 'playbackEnded'].forEach((event) => {
        return dashPlayer.on(event, (videoObj) => {
          return prepareAndSendLogMessage(videoObj, fragmentInfo);
        }, true);
      });
    }
  }

  _handleQualityError(msg) {
    sendLog({
      logData: {
        logMessage: msg,
        logs: false
      }
    });
    logging.warn('Transcoded player quality is changed!');
  }

  onPressCaptionsSelectorButton(e) {
    const $target = $(e.currentTarget);
    $target.trigger('click');
  }

  _onPlayerError() {
    if (this.isDestroyed) {
      return;
    }

    // need to determine if this is the code 4 native html5 media error
    // need to check the dom elements as sometimes the mejs object doesn't
    // show the error but the video tags will.  In this case, this onPlayerError
    // will still get called and display the continue button.
    const validErrors = this.$('video').map((i, e) => {
      const err = $(e).prop('error');
      if (!err || err.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
        return undefined;
      }
      // This is a known issue with Firefox on Mac, where an error is thrown but the video still plays
      if (err.code === MediaError.MEDIA_ERR_DECODE && err.message?.includes('mozilla::AppleATDecoder::GetInputAudioDescription')) {
        return undefined;
      }
      return err.code;
    })
      .get();

    if (validErrors.length > 0) {
      logging.error('MediaElement error');
      this._triggerError();
    }
    sendLog({logData: {
      logMessage: 'Failed to play video',
      validErrors: validErrors
    }});
  }

  onDestroy() {
    clearInterval(this.stallToken);

    if ($os.ios && this.videoElements) {
      _.each(this.videoElements, (video) => {
        video.removeEventListener('loadeddata', this._preventSeekForwardForNative, false);
        video.removeEventListener('enterpictureinpicture', this._waitAndExitPictureInPicture, false);
      });
    }
  }
}

module.exports = TranscodedPlayer;

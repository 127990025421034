const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');

class ReferralHowToModal extends UIKit.View {
  get template() {
    return _.tpl(require('../templates/_referral_how_it_works.html'));
  }

  className() {
    return 'modal';
  }

  constructor(...args) {
    super(...args);
    this.close = this.close.bind(this);
  }

  initialize(options = {}) {
    ({ configuration: this.configuration } = options);
  }

  render() {
    this.$el.html(
      this.template({
        days: this.configuration.get('bonusPointsDuration')
      })
    );

    this.actionBar.setActionBar({
      buttons: {
        type: 'customText',
        text: I18n.t('referral.howTo.accept'),
        onClick: this.close
      }
    });

    return this;
  }

  close() {
    this.dismiss();
  }
}

module.exports = ReferralHowToModal;

const ImageViewer = require('./ImageViewer');

const ImageViewerFactory = {

  /*
    options:
      media (JSON) - Required
      fitToScreen (bool) - Optional
      maxWidth (int: pixels) - Optional
      maxHeight (int: pixels) - Optional
      displayWidth (string: px or %) - Optional
      displayHeight (string: px or %) - Optional
      fallbackAlt (string) - Optional (default alt-text if none provided)
      imgClass (string) - Optional (default class to apply to img)
      keepExistingChildNodes (bool) - Optional
      ariaHidden (bool) - Optional (defaults to false)
  */
  createViewerInstance(options = {}) {
    if (options.media == null) {
      return undefined;
    }
    options.el = options.$el;
    return new ImageViewer(options);
  }
};

module.exports = ImageViewerFactory;

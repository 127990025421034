const _ = require('underscore');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

/**
 * The majority of this class is only used in /training/ for the article/question/link editor.
 * It was setup as is for backwards compatibility with old articles and to maintain a consistent style.
 * If we ever decide to remove the use of <figure> tags, a lot of this can be updated/removed.
 */
const FroalaRichContentHelper = {

  imageTemplate: _.template(`
  <figure class="page__media media--image froala-img fr-dib fr-fil" data-media-id="<%- id %>">
    <div class="image-placeholder"></div>
  </figure>
  `),

  videoTemplate: _.template(`
  <p></p>
  <figure class="page__media media--video froala-vid fr-deletable" data-media-id="<%- id %>" contenteditable="false">
    <img class="video-placeholder" data-media-id="<%- id %>"/>
  </figure>
  <p></p>
  `),

  createImageContainerHtml(imageMedia) {
    // get data required
    const data
      = {id: imageMedia.get('id')};

    return this.imageTemplate(data);
  },

  createVideoContainerHtml(videoMedia) {
    // get data required
    const data
      = {id: videoMedia.get('id')};

    return this.videoTemplate(data);
  },

  /**
   * Clean the content before saving to ensure we keep all relevant tags and attributes
   * and remove anything that the server will not allow.
   * @param {Object} $content - A Jquery object of the content from the editor
   * @returns {Object} A rich content object with the cleaned content and remaining, existing media ids
   */
  cleanContent($content) {
    // need to put content in container in case the content is
    // nothing but a media tag that we need
    const $newContent = $('<tmp></tmp>').append($content);

    // target has to be replaced from _blank to _self for internal links,
    // that were added with paste and not with url button
    $newContent.find('a[target="_blank"]').each((index, element) => {
      if (!UrlHelpers.isExternalLink(element.href)) {
        $(element).attr('target', '_self');
      }
    });

    // Linking to existing content will add a data-media-id, create normal links will add the attribute but won't
    // attach a value. In the latter case we want to clear the attribute.
    $newContent.find('a[data-media-id]').each((index, element) => {
      const $ele = $(element);
      if (element.attributes['data-media-id'].value === '') {
        $ele.removeAttr('data-media-id');
      }
    });

    // Remove the contenteditable attribute from all media to prevent editing in view
    $newContent.find('.page__media').removeAttr('contenteditable');

    // Protect text that was added in IE11, because contenteditable='false' wasn't recognized. Appends text to before the .page__media
    $newContent.find('.page__media.media--video, .page__media.media--embeddedVideo').each((index, element) => {
      const $el = $(element);
      const $prevEl = $el.prev();

      if (!$prevEl.is('p') || $prevEl.text() !== '') {
        const text = $el.text();
        $el.before(`<p>${ text }</p>`);
      }
    });

    // In case content managed to find its way in between the figure and image tags
    this.moveContentOutsideImageContainer($newContent);

    // XXX - remove the class and data-media-id attributes from
    // the div containers that don't contain img
    // An issue was created: https://github.com/neilj/Squire/issues/179
    $newContent.find('.page__media').not(':has(img)')
      .removeAttr('class')
      .removeAttr('data-media-id')
      .removeData();

    // Any figures that don't have a class should be removed.
    $newContent.find('figure:not(.page__media)').remove();

    // Move the style and froala classes of the img to the parent.
    $newContent.find('img').not('.video-placeholder')
      .each((index, element) => {
        const $parent = this.getImageParentElement($(element));
        const style = $(element).attr('style');
        if (style) {
          $parent.attr('style', style);
        }
        const width = element.style.width;
        const height = element.style.height;
        if (!width && !height) {
          $parent.width(element.width + 'px');
        } else {
          $parent.width(width);
          $parent.height(height);
        }
        $parent.removeClass(this.getFroalaClassNamesToMove($parent[0]));
        $parent.addClass(this.getFroalaClassNamesToMove(element));
      });

    // Move all html that is not image-specific out of figure tags
    $newContent.find('.page__media').each((index, element) => {
      const $element = $(element);
      $element.find('*')
        .not('a, img, .fr-img-space-wrap, .fr-img-caption, .fr-img-caption *') //keep imgs, captions, & links
        .removeClass('fr-img-space-wrap2') // Inner spans get this class auto-assigned by froala, so remove it.
        .insertAfter($element); // Move the element outside of the 'figure' / 'page__media' tag.
      const text = $element.contents().not($element.children()) // Ignore child nodes when selecting bare text
        .text();
      if (text) {
        $element.text('');
        $element.after(text);
      }
    });

    // remove all image tags -- we only store divs/figures with data-media-id references
    // that will be loaded into img tags by the ImageViewer component
    $newContent.find('img').remove();

    // remove any instance of selection target, as it's only used by the editor
    this.removeSelectionTarget($newContent);

    // need to get the list of media-id still contained
    const $mediaContainers = $newContent.find('.page__media');
    const mediaIdList = $mediaContainers.map((index, el) => {
      return $(el).data('mediaId');
    }).get();

    this.stripEmptyParagraphs($newContent);
    this.stripDisallowedAttributesFromWordPaste($newContent);
    $newContent.find('br').removeAttr('class'); // Special case can come up where <br> has a class (which we don't allow)

    const richContent = {
      content: $newContent[0].innerHTML,
      mediaIdList
    };

    richContent.content = this.sanitizeEntitiesForTranslation(richContent.content);

    return richContent;
  },

  sanitizeEntitiesForTranslation(richContent) {
    let content = richContent;

    // replace any non-breaking spaces with a normal space
    content = content.replace(/&nbsp;/g, ' ');

    return content;
  },

  // This may need to be called during editing (for example on refresh) to avoid froala's habit of inserting <br> in
  // empty paragraphs and thus screwing up formatting.
  stripEmptyParagraphs($newContent) {

    let $contentCopy = $newContent;
    const isString = typeof $contentCopy === 'string';

    if (isString) {
      $contentCopy = $('<tmp></tmp>').append($newContent);
    }

    const emptyParagraphs = $contentCopy.find('p').filter((index, element) => {
      const $el = $(element);
      return $el.text().length === 0 && $el.children().length === 0;
    });

    emptyParagraphs.remove();
    if (isString) {
      return $contentCopy[0].innerHTML;
    }
    return $contentCopy;
  },

  // Wraps a media element
  _wrapElement($element, mediaType) {
    const mediaId = $element.find('img').data('mediaId');
    const $wrapperDiv = $('<div>').addClass(`page__media media--${ mediaType }`)
      .attr('data-media-id', mediaId);
    $element.find('img').wrap($wrapperDiv);
  },

  /**
   * This method accepts a placeholder element, and returns the element that ImageViewerFactory.createViewerInstance
   * should use as the container into which it will inject the <img>. Because you see, sometimes there is extra stuff
   * inside the placeholder -- like a span or a link. In the case of a span (which will have a "fr-inner" class), that
   * span is intended to be the container of the <img>. And when there's an <a>, the <img> should go inside that.
   * @param {*} $el - the placeholder element, ie something with a .page__media class and a data-mediaId attribute
   * @returns - the element that should be used as the container for an injected <img>.
   */
  getImageParentElement($el) {
    // Make sure we are looking at the figure and not one of its children
    const $pageMedia = $el.closest('.page__media');

    const $innerSpan = $pageMedia.find('.fr-inner');
    const $link = $pageMedia.find('a');

    if ($innerSpan.length > 0) {
      return $innerSpan.parent();
    } else if ($link.length > 0) {
      return $link;
    }
    return $pageMedia;
  },

  // When loading old articles, need to update the placeholder for different types of videos.
  updateVideoPlaceholders($content) {
    const $newContent = $('<tmp></tmp>').append($content);
    const video = $newContent.find('.page__media.media--video, .page__media.media--embeddedVideo, .fr-video');
    video.addClass('fr-deletable');
    return $newContent[0].innerHTML;
  },

  // When saving, we move certain class names from the img tag to its parent figure then back again on img load
  getFroalaClassNamesToMove(element) {
    const dontStrip = ['fr-caption', 'fr-img-space-wrap', 'fr-draggable', 'fr-image-wrap', 'fr-inner', 'fr-img-wrap'];
    if (!element || !element.className) {
      return '';
    }
    return element.className.split(' ').filter((c) => {
      return c.startsWith('fr-') && !dontStrip.includes(c);
    });
  },

  // TODO: Add a validator for tags to ensure we get descriptive errors on what's not allowed.
  // TODO: Use the XSS Scrubber on wordPaste / froala's pasteDeniedTags to ensure we get rid of disallowed tags
  // Froala adds a bunch of attributes, particularly with word paste, and some of those are completely unnecessary
  // for functionality and disallowed by the server, so we strip them out on save to ensure the user doesn't get
  // a generic "HTML Tags" error.
  stripDisallowedAttributesFromWordPaste($content) {

    // Disallow internal links (Word docs do this with comments / ToC)
    const $linksToReplace = $content.find('a[href^="#"]');
    $linksToReplace.each((index, element) => {
      const $el = $(element);
      const text = $el.text();
      const $span = $('<span></span>');
      $span.append($el.children());
      $span.text(text);
      $el.replaceWith($span);
    });

    // Remove attributes added by word paste
    // remove language
    const $toStrip = $content.find('[language]');
    $toStrip.attr({
      language: null
    });

    // remove rel, name, and id for anchor tags
    const $linksToStrip = $content.find('a');
    $linksToStrip.attr({
      name: null,
      rel: null,
      id: null
    });

    // remove data attributes other than the allowed
    const allowedDataAttrs = {
      a: ['data-media-id'],
      div: ['data-media-id', 'data-empty', 'data-uuid'],
      figure: ['data-alt-text', 'data-media-id', 'data-source'],
      img: ['data'],
      span: ['data-contrast', 'data-fontsize']
    };
    this.removeDisallowedDataAttributes($content, allowedDataAttrs);

    // remove br styling
    $content.find('br[style]').removeAttr('style');
    // Sometimes copy/paste will inject formatting that shouldn't be there.
    // Specifically on <em> and <tbody> tags (CI-947), so we remove the injected styles and ensure everything works correctly
    $content.find('[style*="-webkit-tap-highlight-color"]').css('-webkit-tap-highlight-color', '');
    $content.find('em, tbody').removeAttr('style');
    $content.find('[style=""]').removeAttr('style'); //cleanup of any remaining empty style attributes
    $content.find('table').removeAttr('title'); // Table titles are disallowed by server
  },

  /**
   * Recursively remove disallowed data attributes from a node and its children.
   * @param {Object} $handle - Jquery object pointing to the current element to be cleaned
   * @param {*} allowedAttrs - An object with key = tag name, value = Array of allowed data-attributes (strings)
   */
  removeDisallowedDataAttributes($handle, allowedAttrs = {}) {
    $handle.contents().each((index, target) => {
      const $target = $(target);
      // Loop through data attributes.
      Object.keys($target.data()).map((key) => {
        // CamelCase to kabob-case
        const attr = 'data-' + key.replace(/([A-Z])/g, '-$1').toLowerCase();
        const allowedAttrsForTag = allowedAttrs[target.tagName.toLowerCase()] || [];
        if (!allowedAttrsForTag.includes(attr)) {
          $target.removeAttr(attr);
        }
      });
      this.removeDisallowedDataAttributes($target, allowedAttrs);
    });
  },

  // Wrap a text node in a 'p' tag if necessary.
  wrapTextNode(node) {
    let $node = $(node);
    if (node.nodeType === Node.TEXT_NODE) {
      $node.wrap('<p></p>');
      $node = $node.parent();
    }
    return $node;
  },

  /**
   * Move content outside of image containers if necessary. Takes the root node and checks all image media
   * within it to determine what needs to be moved.
   * @param {Object} $content - Jquery object of root content to work on
   * @returns {null|boolean|object} Returns a jquery object pointing to the moved content OR
   *  true if some content was removed OR
   *  null if nothing was updated
   */
  moveContentOutsideImageContainer($content) {
    let contentUpdated = null;
    this.removeSelectionTarget($content);
    $content.find('.page__media.media--image').each((index, element) => {
      let foundImg = false; // If we haven't found the image yet, insert before, else after.
      const $element = $(element);
      // Rare case we can have an empty figure tag (image added, then selected and deleted),
      // but Froala inserts a <br> into it by default
      if ($element.children().length === 1 && $element.children()[0].nodeName === 'BR') {
        $element.remove();
        contentUpdated = true;
      } else {
        $(element).contents()
          .each((indx, childElement) => {
            const $childEle = $(childElement);
            // The image will always be the child of link, img-space-wrap, or the parent directly (ie. it will be an img).
            if ($childEle.is('img, a, .fr-img-space-wrap')) {
              foundImg = true;
              // For captions move out the fr-img-space-wrap2 paragraphs. Froala auto-generates them.
              // They can also be left behind after removing captions, so check in all cases.
              const $toMove = $childEle.contents().filter((i, child) => {
                // Ignore &nbsp children
                return ($(child).hasClass('fr-img-space-wrap2') || child.nodeType === Node.TEXT_NODE) && child.textContent !== '\xA0';
              });
              if ($toMove.length > 0) {
                $toMove.each((i, eleToMove) => {
                  contentUpdated = this.moveContent(eleToMove, $(element), foundImg);
                });
              }
              // Froala likes to add an &nbsp after a caption, we don't want to move it around.
            } else if (childElement.textContent !== '\xA0') {
              contentUpdated = this.moveContent(childElement, $(element), foundImg);
            }
          });
      }
    });
    // For updating the editor content and repositioning the editor cursor as necessary
    return contentUpdated;
  },

  /**
   * Moves content around a target
   * @param contentToMove - The content to move
   * @param $target - The target of where to move it
   * @param insertAfter - If true insert the content after the target, otherwise insert it before
   * @returns The updated content.
   */
  moveContent(contentToMove, $target, insertAfter = false) {
    // Move unwrapped text into a 'p' tag before moving it
    const $temp = this.wrapTextNode(contentToMove);
    if (insertAfter) {
      $temp.insertAfter($target);
    } else {
      $temp.insertBefore($target);
    }
    // Selection target needs to move to allow the user to continue typing.
    $temp.addClass('js-selection-target');
    return $temp;
  },

  removeSelectionTarget($content) {
    $content.find('.js-selection-target').removeClass('js-selection-target');
  },

  hasContent(editorContent, mediaIdList) {
    const $editorContent = $(editorContent);
    const $embededVideos = $editorContent.find('.media--embeddedVideo, .fr-video iframe');
    const contents = $editorContent.contents().text()
      .trim();

    const hasTextContents = contents.length > 0;
    const hasInlineMediaContents = mediaIdList.length > 0;
    const hasInlineVideoEmbeds = $embededVideos.length > 0;

    return hasTextContents || hasInlineMediaContents || hasInlineVideoEmbeds;
  },

  /**
   * Broadcast Editor images created with CKEditor do not get swapped with a placeholder as no media model is available
   * and no media-id exists in the HTML. For those images we need to enforce they adhere to the XSS restrictions and
   * remove any classes - this means they cannot be updated to work with Froala's image placement and would need to be
   * reimported if that were the case
   * @param {Object} $content - JQuery object of the content to be cleaned
   * @returns {string} The cleaned content
   */
  cleanOldImages($content) {
    const $images = $content.find('img:not([data-media-id])');
    $images.removeAttr('class');
    return $content[0].innerHTML;
  },

  /**
   * If you load the editor for any language, an empty <p> tag with &nbsp; will be created. This check ensures
   * we do not save translations that are empty.
   * @param {string} messageText - The editor contents we are checking
   * @returns {boolean} If true the editor is only whitespace, empty <p> tags, or &nbsp; characters
   */
  isMessageEmpty(messageText) {
    if (!messageText || typeof messageText !== 'string') {
      return true;
    }

    // If you load the editor, an empty <p> tag / <p> with &nbsp; will be created so we want to check if it's empty
    const text = messageText.replace(/&nbsp;/g, '')
      .replace(/\s/g, '')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '')
      .replace(/\s+/g, '');
    return (text.length === 0);
  }

};

module.exports = FroalaRichContentHelper;

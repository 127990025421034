const { settings } = require('env');
const $ = require('jquery');
const _ = require('underscore');

const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const UrlHelpers = {
  replaceUrl(url) {
    try {
      window.location.replace(url);
    } catch (e) {
      window.location.assign(url);
    }
  },

  replaceState(state = {}, title = '', url = '') {
    if (BrowserHelpers.isHistoryAPISupported()) {
      window.history.replaceState(state, title, url);
    } else {
      this.replaceUrl(url);
    }
  },

  getLocationHash() {
    const { hash } = window.location; // incidentally, this makes a method impossible to unit test
    const queryString = UrlHelpers.getQueryString(hash);
    return hash.replace(queryString, '');
  },

  getQueryString(searchStr) {
    const index = searchStr.indexOf('?');
    if (index >= 0) {
      return searchStr.slice(index);
    } return '';
  },

  // This makes no assumptions about what part of the url is passed in.
  // It does rely on the query params being at the end of the string.
  getQueryParams(searchStr = '') {
    const origin = UrlHelpers.getLocationOrigin(window.location);
    const url = new URL(searchStr, origin);
    const hashUrl = new URL(url.hash.slice(1), origin);
    const paramList = Array.from(url.searchParams).concat(Array.from(hashUrl.searchParams));

    // generate a object of the params with decoded values (searchParams does the decoding).
    return paramList.reduce((memo, [key, value]) => {
      memo[key] = value;
      return memo;
    }, {});
  },

  // This function may reload the page if HTML5 is not supported on the browser that is running this code.
  // Keep this in mind.
  clearQueryParams(params = [], location = window.location) {
    const str = UrlHelpers.getQueryString(location.search);
    const currentParameters = this.getQueryParams(str);

    // Reject the parameters currently we don't want that are in use
    const newParameters = _.omit(currentParameters, params);
    const newURL = this.replaceQueryParams(location.href, newParameters);

    this.replaceState(newParameters, '', newURL);
  },

  // Takes a url string and adds the set of params to it, returning the final url.
  appendQueryParams(path = '', params = {}) {
    const url = new URL(path, UrlHelpers.getLocationOrigin(window.location));

    // set the new params overriding the existing ones.
    _.each(params, (value, key) => {
      url.searchParams.set(key, value);
    });

    const href = url.toString();

    // only return the part of the string starting at where the path started.
    // This compensates for the baseUrl that gets passed into URL().
    return href.slice(href.indexOf(path.split('?')[0]));
  },

  replaceQueryParams: (path = '', params = {}) => {
    const url = new URL(path, UrlHelpers.getLocationOrigin(window.location));

    // wipe out all existing params
    url.search = '';

    // append new params
    _.each(params, (value, key) => {
      url.searchParams.append(key, value);
    });

    const href = url.toString();

    // only return the part of the string starting at where the path started.
    // This compensates for the baseUrl that gets passed into URL().
    return href.slice(href.indexOf(path.split('?')[0]));
  },

  getLocationOrigin: (location = window.location) => {
    const { origin } = location;

    if (origin != null) {
      return origin;
    }

    const {
      protocol,
      hostname,
      port
    } = location;

    const portStr = port ? `:${ port }` : '';

    return `${ protocol }//${ hostname }${ portStr }`;
  },

  getRelativeUrl(relativeUrl = '', queryParams) {
    let url = relativeUrl;
    let params = queryParams;

    // add query params if there are some
    if (!params) {
      params = UrlHelpers.getQueryParams(window.location.search);
    }

    if (!_.isEmpty(params)) {
      url += `?${ $.param(params) }`;
    }

    return url;
  },

  isDrillDownPath(prevFragment, currFragment) {
    // if either prevFragment or currFragment is null/undefined it's not a drill-down path
    if (!prevFragment || !currFragment) {
      return false;
    }

    // count the number of levels in the URL since we can't just go by length of strings
    // e.g. comparing string length, this would return true when it should be false:
    // prevFragment = 'manage'
    // currFragment = 'manager'
    let prevParts = prevFragment.split('/');
    let currParts = currFragment.split('/');
    // leading or trailing slashes will result in empty parts we don't want
    prevParts = _.without(prevParts, '');
    currParts = _.without(currParts, '');
    // it can't be a drill-down unless the previous path was shallower
    if (!(prevParts.length < currParts.length)) {
      return false;
    }

    // if the subset of currParts (equal to length of prevParts) doesn't equal prevParts
    // then it can't be a drill-down path
    const currPartsSubset = currParts.slice(0, prevParts.length);
    if (!_.isEqual(prevParts, currPartsSubset)) {
      return false;
    }

    return true;
  },

  // Given a Backbone.history.fragment
  // remove the last item and join
  // to create the previous url
  getParentFragment(currFragment = '', ancestorsToRemove = 1) {
    const currParts = currFragment.split('/');
    let toRemove = ancestorsToRemove;

    while (toRemove > 0 && currParts.length > 0) {
      currParts.pop();
      toRemove--;
    }

    return currParts.join('/');
  },

  combineUrlPaths(...parts) {
    // filter out empty parts which could put unwanted slashes
    // e.g. parts = ['', 'hello', '', '/world', ''] would otherwise yield '/hello/world/' when we want 'hello/world'
    // but parts = ['oh', 'hello', '', '/world', '/'] would still yield 'oh/hello/world/' in both cases
    // also, only look at strings and finite numbers to handle this extreme test case:
    // e.g. parts = ['', 'hello', window, ['test', {}, [], '', NaN], undefined, true, -Infinity,
    // `function(){}`, 3, '/world', ''] yields 'hello/test/3/world'
    let paths = _.flatten(parts);
    paths = _.filter(paths, (path) => {
      return _.isString(path) || (_.isNumber(path) && _.isFinite(path)) && path.toString().length > 0;
    });

    return UrlHelpers.joinUrlFragments(paths);
  },

  joinUrlFragments(fragments = [], separator = '/') {
    const regExp = new RegExp(`\\${ separator }+`, 'g');
    return _.compact(fragments).join(separator)
      .replace(regExp, separator);
  },

  parseDzLink(href) {
    if (href == null) {
      return {};
    }

    const match = href.match(/^https?:\/\/([^:/?#]*)\.axonify\.com[/].*article\/([0-9]*).*$/);

    return match && {
      href,
      tenant: match[1].toLowerCase(),
      pageId: match[2]
    };
  },

  prependBaseScriptUrl(path = '') {
    return `${ settings.baseScriptURL }${ path }`;
  },

  setUrlProtocol(url) {
    const trimmedUrl = url.trim();
    const isMailtoOrTel = Boolean(trimmedUrl.indexOf('mailto:') === 0 || trimmedUrl.indexOf('tel:') === 0);
    const urlProtocolRegex = /^[^:]+(?=:\/\/)/;

    // If url is not a mailto, or does not contain a protocol
    // default to http
    if ((trimmedUrl.search(urlProtocolRegex) === -1) && !isMailtoOrTel) {
      return `http://${ trimmedUrl }`;
    }
    return trimmedUrl;

  },

  isExternalLink(url, location = window.location) {
    const validProtocols = ['http:', 'https:'];
    const urlLocation = document.createElement('a');
    urlLocation.href = url;

    // hostnames must match
    let isInternalLink = urlLocation.hostname === location.hostname;
    // must be http or https protocol
    isInternalLink = isInternalLink && validProtocols.includes(urlLocation.protocol.toLowerCase());

    return !isInternalLink;
  },

  getSubdomain() {
    return window.location.host.split('.')[0];
  },

  parseStringId(value, fallback = undefined) {
    return parseInt(value, 10) || fallback;
  },

  getLinkToAdminUserProfile(userId) {
    if (!_.isNumber(userId)) {
      throw new Error('userId is a required variable');
    }

    return `/admin/index.html#users/${ userId }/edit`;
  },

  // Returns non-retina image url for the given image url
  coachUrl(imageUrl) {
    if (!imageUrl) {
      return '';
    }
    const parts = imageUrl.split('.');
    return parts.slice(0, -1).join('.') + '_coach.' + parts.slice(-1);
  },

  // Returns retina image url for the given image url
  retinaCoachUrl(imageUrl) {
    if (!imageUrl) {
      return '';
    }
    const parts = imageUrl.split('.');
    return parts.slice(0, -1).join('.') + '_retina_coach.' + parts.slice(-1);
  },

  // Taken from https://stackoverflow.com/questions/2540969/remove-querystring-from-url
  getUrlWithoutParams(url) {
    return url.split('?')[0];
  },

  isUrl(value, protocolWhitelist = []) {
    let whitelist = ['http', 'https'];
    if (protocolWhitelist) {
      whitelist = [...new Set([...whitelist, ...protocolWhitelist])];
    }

    // eslint-disable-next-line no-useless-escape
    const pattern = new RegExp(`(${ whitelist.join('|') }):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-\\/]))?`);

    return pattern.test(value);
  },

  isUri(value) {
    // pattern from https://snipplr.com/view/6889/regular-expressions-for-uri-validationparsing
    // eslint-disable-next-line no-useless-escape
    const pattern = new RegExp(/^([a-z][a-z0-9+.-]*):(?:\/\/((?:(?=((?:[a-z0-9-._~!$&'()*+,;=:]|%[0-9A-F]{2})*))(\3)@)?(?=(\[[0-9A-F:.]{2,}\]|(?:[a-z0-9-._~!$&'()*+,;=]|%[0-9A-F]{2})*))\5(?::(?=(\d*))\6)?)(\/(?=((?:[a-z0-9-._~!$&'()*+,;=:@\/]|%[0-9A-F]{2})*))\8)?|(\/?(?!\/)(?=((?:[a-z0-9-._~!$&'()*+,;=:@\/]|%[0-9A-F]{2})*))\10)?)(?:\?(?=((?:[a-z0-9-._~!$&'()*+,;=:@\/?]|%[0-9A-F]{2})*))\11)?(?:#(?=((?:[a-z0-9-._~!$&'()*+,;=:@\/?]|%[0-9A-F]{2})*))\12)?$/i);

    return pattern.test(value);
  },

  isSafeRedirectUrl(redirectUrl, currentUrl = window.location.href, tenantPropertyProvider = TenantPropertyProvider) {
    if (!tenantPropertyProvider.get().getProperty('restrictedRedirectUrlEnabled')) {
      return true;
    }

    const redirectElLink = document.createElement('a');
    redirectElLink.href = redirectUrl;

    const currentElLink = document.createElement('a');
    currentElLink.href = currentUrl;

    return currentElLink.host === redirectElLink.host;
  }
};

module.exports = UrlHelpers;

const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

class GuidedLearningExplanationModalItem extends Marionette.ItemView {
  className() {
    return 'gl-modal__item ax-grid ';
  }

  getTemplate() {
    return require('@training/apps/training/views/guidedLearning/modal/GuidedLearningExplanationModalItem.html');
  }

  templateHelpers() {
    return {
      key: this.model.get('key'),
      iconKey: this.model.get('iconKey') || this.model.get('key'),
      tagText: I18n.t(`GuidedLearning.icons.${ this.model.get('key') }`),
      contentText: I18n.t(`GuidedLearning.ExplanationModal.${ this.model.get('key') }`)
    };
  }

  ui() {
    return {
      content: '.gl-model__content'
    };
  }
}

module.exports = GuidedLearningExplanationModalItem;

import {
  AxIcon,
  AxIconButton,
  AxInputAdornment,
  AxTextField
} from '@common/modules/react/themes/components';
import { styled } from '@mui/material';
import {
  LocalizationProvider,
  DesktopDateTimePicker as MuiDateTimePicker,
  type DateTimePickerProps as MuiDateTimePickerProps,
  type PickerValidDate as MuiPickerValidDate
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  forwardRef,
  type Ref
} from 'react';

export interface AxDateTimePickerProps<
  TDate extends MuiPickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
> extends MuiDateTimePickerProps<TDate, TEnableAccessibleFieldDOMStructure> {
  required?: boolean;
}

const styledOptions = {
  name: 'AxDateTimePicker'
};

const StyledAxDateTimePicker = styled(MuiDateTimePicker, styledOptions)(() => {
  // TODO - update to use common variables
  return {};
});

export const AxDateTimePicker = forwardRef(<
  TDate extends MuiPickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
>(
    {
      slots = {},
      slotProps = {},
      format = 'LL hh:mm a',
      required = false,
      ...DateTimePickerProps
    }: AxDateTimePickerProps<TDate, TEnableAccessibleFieldDOMStructure>,
    ref: Ref<HTMLDivElement>
  ) => {
  return (
    <LocalizationProvider dateAdapter={ AdapterMoment } adapterLocale='userLocale'>
      <StyledAxDateTimePicker
        format={ format }
        { ...DateTimePickerProps }
        slots={{
          ...slots,
          textField: AxTextField,
          inputAdornment: AxInputAdornment,
          openPickerButton: AxIconButton,
          openPickerIcon: AxIcon
        }}
        slotProps={{
          ...slotProps,
          textField: {
            required: required,
            ...(slotProps?.textField ?? {})
          },
          openPickerButton: {
            size: 'medium',
            ...(slotProps?.openPickerButton ?? {})
          },
          openPickerIcon: {
            className: 'icon-frequenttraining',
            ...(slotProps?.openPickerIcon ?? {})
          }
        }}
        ref={ ref }
      />
    </LocalizationProvider>
  );
});

export default AxDateTimePicker;

/* eslint-disable no-undef */
const _ = require('underscore');
const moment = require('moment');

// required for the side-effect of loading in all the timezone data for the timezone
// related functions inside of this static helper
require('moment-timezone-with-data');

const I18n = require('@common/libs/I18n');

// Please remember that all examples show only general format idea,
// because all formats use locale spesific files
// and format can be changed per locale, but the main idea behind formats is kept
module.exports = {

  //moment supports today date creation if {} or [] passed as a date
  convertDateFormat(date, inFormat = 'epoch') {
    if (inFormat === 'epoch') {
      return date;
    }

    return this.createDate(date, inFormat).valueOf();

  },

  convertUtcDateFormat(date, inFormat = 'epoch') {
    if (inFormat === 'epoch') {
      return date;
    } return this.createUtcDate(date, inFormat).valueOf();
  },

  // All the  I18n.t default parameters are intentionally left here,
  // since we don't want this inlined into the function definition in newer JS versions
  // since it's a computed function value and we want them recomputed each time

  // Example: 5:11 PM
  convertDateFormatTime(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('LT');
  },

  // Example: 5:11 PM
  convertUtcDateFormatTime(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createUtcDate(this.convertDateFormat(date, inFormat)).format('LT');
  },

  // Example: 5:11:08 PM
  convertDateFormatTimeWithSeconds(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('LTS');
  },

  // Example: 09/09/2016
  convertDateFormatLongDateOnlyNumbers(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('L');
  },

  // Example: 2015-06-17 14:24:36 (in browser timezone)
  convertDateFormatLongDateTimeOnlyNumbers(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('YYYY-MM-DD HH:mm:ss');
  },

  // Example: 2015-06-17 14:24:36
  convertTimeZoneDateFormatLongDateTimeOnlyNumbers(date, timeZone, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).tz(timeZone)
      .format('YYYY-MM-DD HH:mm:ss');
  },

  // Example: 2015-06-17 14:24:36 (date should be in UTC time)
  convertUtcDateFormatLongDateTimeOnlyNumbers(date, inFormat) {
    const timeZone = 'UTC';
    return this.convertTimeZoneDateFormatLongDateTimeOnlyNumbers(date, timeZone, inFormat);
  },

  // Example: September 2016
  convertDateFormatMonthNameWithYear(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('MMMM YYYY');
  },

  // Example: September 9, 2016
  convertDateFormatLongDateWithStrings(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('LL');
  },

  // Example: September 9, 2016
  convertUtcDateFormatLongDateWithStrings(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createUtcDate(this.convertDateFormat(date, inFormat)).format('LL');
  },

  // Example: September 9, 2016 5:11 PM
  convertDateFormatLongDateTimeWithStrings(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('LLL');
  },

  // Example: September 9, 2016 5:11 PM (date should be in UTC time)
  convertUtcDateFormatLongDateTimeWithStrings(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createUtcDate(this.convertDateFormat(date, inFormat)).format('LLL');
  },

  // Example: September 9, 2016 5:11 PM -04:00 EDT
  convertDateFormatLongDateTimeWithStringsWithTimezone(date, timeZone, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).tz(timeZone)
      .format('LLL Z z');
  },

  // Example: September 9, 2016 5:11 PM EDT
  convertDateFormatLongDateTimeWithStringsWithTimezoneWithoutOffset(date, timeZone, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).tz(timeZone)
      .format('LLL z');
  },

  // Example: September 9, 2016 5:11 PM
  convertDateInTimezoneFormatLongDateTimeWithStrings(date, timeZone, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).tz(timeZone)
      .format('LLL');
  },

  // Example: 9/9/2016
  convertDateFormatShortDateOnlyNumbers(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('l');
  },

  // Example: Sep 9, 2016
  convertDateFormatShortDateWithStrings(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('ll');
  },


  // Example: Sep 9, 2016 but keeps the UTC value
  convertUtcDateFormatShortDateWithStrings(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createUtcDate(this.convertDateFormat(date, inFormat)).format('ll');
  },

  // Example: Mar 30
  convertDateFormatShortDateWithStringsAndNoYear(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('MMM DD');
  },

  // Example: Wed
  convertDateFormatWeekdayShort(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('ddd');
  },

  // Example: Wednesday
  convertDateFormatWeekdayLong(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('dddd');
  },

  // Example: Sep 9, 2016 5:11 PM
  convertDateFormatShortDateTimeWithStrings(date, inFormat) {
    if (date == null) {
      return I18n.t('general.noValue');
    }
    return this.createDate(this.convertDateFormat(date, inFormat)).format('lll');
  },

  convertDateFormatCustom(date, inFormat, outFormat) {
    if (date == null) {
      return undefined;
    }

    if (outFormat === 'epoch') {
      return this.convertDateFormat(date, inFormat);
    }

    return this.createDate(this.convertDateFormat(date, inFormat)).format(outFormat);
  },

  convertDateFormatToDefaultDate(date, inFormat) {
    return this.convertDateFormatLongDateWithStrings(date, inFormat);
  },

  convertDateFormatToDefaultDateTime(date, inFormat) {
    return this.convertDateFormatLongDateTimeWithStrings(date, inFormat);
  },

  convertUtcDateFormatToDefaultDate(date, inFormat) {
    return this.convertUtcDateFormatLongDateWithStrings(date, inFormat);
  },

  convertUtcDateFormatToDefaultDateTime(date, inFormat) {
    return this.convertUtcDateFormatLongDateTimeWithStrings(date, inFormat);
  },

  convertDateFormatToDefaultDateTimeZone(date, timezone, inFormat) {
    return this.convertDateFormatLongDateTimeWithStringsWithTimezone(date, timezone, inFormat);
  },

  // functions for getting locale formats main use to create locale place holders
  // Example: 5:11 PM
  getFormatTime() {
    return this.getLocaleDateFormat('LT');
  },

  // Example: 5:11:08 PM
  getFormatTimeWithSeconds() {
    return this.getLocaleDateFormat('LTS');
  },

  // Example: 09/09/2016
  getFormatLongDateOnlyNumbers() {
    return this.getLocaleDateFormat('L');
  },

  // Example: September 9, 2016
  getFormatLongDateWithStrings() {
    return this.getLocaleDateFormat('LL');
  },

  // Example: September 9, 2016 5:11 PM
  getFormatLongDateTimeWithStrings() {
    return this.getLocaleDateFormat('LLL');
  },

  // Example: 9/9/2016
  getFormatShortDateOnlyNumbers() {
    return this.getLocaleDateFormat('l');
  },

  // Example: Sep 9, 2016
  getFormatShortDateWithStrings() {
    return this.getLocaleDateFormat('ll');
  },

  // Example: Sep 9, 2016 5:11 PM
  getFormatShortDateTimeWithStrings() {
    return this.getLocaleDateFormat('lll');
  },

  // Example: GMT-04:00 EDT
  getFormatTimeZoneNowWithAbbreviation(timezone) {
    return `GMT${ moment.tz(timezone).format('Z z') }`;
  },

  getTimeZoneAbbreviation(timezone) {
    return moment.tz(timezone).format('z');
  },

  // Time Zone abbreviation at a particular timestamp
  // (needed because of Daylight Saving Time that can change the offset of a time zone from UTC)
  // Example: GMT-04:00 EDT
  getFormatTimeZoneTimestampWithAbbreviation(timezone, timestamp) {
    return `GMT${ moment.tz(timestamp, timezone).format('Z z') }`;
  },

  // Transparently handles getting a description of the timezone at a
  // particular timestamp relative to UTC.  Also handles the case that
  // utcTimestamp is null---in which case it uses "now" to generate the
  // abbreviation.
  getTimeZoneDescriptionAtUtcTime(timeZone, utcTimestamp) {
    if (utcTimestamp != null) {
      const timeZoneTimestamp = this.getSameUtcTimeInTimeZone(utcTimestamp, timeZone);
      return this.getFormatTimeZoneTimestampWithAbbreviation(timeZone, timeZoneTimestamp);
    }
    return this.getFormatTimeZoneNowWithAbbreviation(timeZone);
  },

  getFormatToDefaultDate() {
    return this.getFormatLongDateWithStrings();
  },

  getFormatToDefaultDateTime() {
    return this.getFormatLongDateTimeWithStrings();
  },

  getLocaleDateFormat(format = 'L') {
    return moment.localeData().longDateFormat(format);
  },

  getWeekdayNames(useCurrentLocale = false) {
    return moment.weekdays(useCurrentLocale);
  },

  getWeekdayNamesShort(useCurrentLocale = false) {
    return moment.weekdaysShort(useCurrentLocale);
  },

  ISODateToTimestamp(dateString) {
    return this.convertDateFormatCustom(dateString, 'YYYY-MM-DD', 'epoch');
  },

  timestampToISODate(timestamp) {
    return this.convertDateFormatCustom(timestamp, 'epoch', 'YYYY-MM-DD');
  },

  // Example: 2016-9
  timestampToISOYearShortMonthDate(timestamp) {
    return this.convertDateFormatCustom(timestamp, 'epoch', 'YYYY-M');
  },

  // Example: 2016-9-6
  timestampToISOYearShortMonthShortDayDate(timestamp) {
    return this.convertDateFormatCustom(timestamp, 'epoch', 'YYYY-M-D');
  },

  dateTimeFromEpoch(timestamp) {
    return {
      date: this.convertDateFormatToDefaultDate(timestamp),
      time: this.convertDateFormatTime(timestamp)
    };
  },

  utcDateTimeFromEpoch(timestamp) {
    return {
      date: this.convertUtcDateFormatToDefaultDate(timestamp),
      time: this.convertUtcDateFormatTime(timestamp)
    };
  },

  warehouseDate(values) {
    if (values == null) {
      return undefined;
    }

    const warehouseDate = {
      year: values.year,
      month: (values.monthInYear != null ? values.monthInYear : 1) - 1,
      day: values.dayInMonth,
      hour: values.hour,
      minute: values.minute
    };

    return this.createDate(warehouseDate);
  },

  durationFromSeconds(seconds = 0) {
    const duration = moment.duration(seconds, 'seconds');

    return {
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
      milliseconds: duration.milliseconds()
    };
  },

  durationInMilliseconds(...args) {
    return moment.duration(...args).asMilliseconds();
  },

  durationFormatFromSeconds(seconds = 0) {
    const duration = this.durationFromSeconds(seconds);
    let time = '';

    if (duration.days > 0) {
      time += I18n.t('time.durationFormat.days', { days: duration.days });
    }
    if (duration.hours > 0) {
      time += I18n.t('time.durationFormat.hours', { hours: duration.hours });
    }
    if (duration.minutes > 0) {
      time += I18n.t('time.durationFormat.minutes', { minutes: duration.minutes });
    }
    if (duration.seconds > 0) {
      time += I18n.t('time.durationFormat.seconds', { seconds: duration.seconds });
    }

    return time.trim();
  },

  // This method will parse a timespan string HOURS:MINUTES:SECONDS.
  // For example: "01:01:5.050" will return number 3665.050
  parseTimeSpanString(timeSpanString = '') {
    const { length } = timeSpanString.split(':');

    if (length === 0) {
      return timeSpanString;
    }

    if (length > 2) {
      return moment.duration(timeSpanString).asSeconds();
    }
    return moment.duration(`00:${ timeSpanString }`).asSeconds();

  },

  getTimeSpanStringFromSeconds(seconds) {
    const duration = this.durationFromSeconds(seconds);
    const format = duration.hours > 0 ? 'HH:mm:ss.SSS' : 'mm:ss.SSS';
    return this.createDate(duration).format(format, true);
  },

  // will return string for example: 201608
  getCurrentMonthYearAsString() {
    return this.createDate().format('YYYYMM');
  },

  // will return string for example: 201608
  getPreviousMonthYearAsString() {
    return this.createDate().subtract(1, 'month')
      .format('YYYYMM');
  },

  // will return string for example: 13:30
  getMilitaryTimeFromDate(date) {
    return moment(this.dateTimeFromEpoch(date).time, ['h:mm A']).format('HH:mm');
  },

  // Will return an object, month range is from 1-12
  // for example: {currentYear: 2016, currentMonth: 8, currentDay: 22}
  getCurrentDayMonthYear(offsetMillis = 0) {
    const current = this.createDate().add(offsetMillis, 'milliseconds');

    return {
      currentYear: current.year(),
      currentMonth: current.month() + 1,
      currentDay: current.date()
    };
  },

  setNow(offsetMillis = 0) {
    // Using the browsers Date here instead of dateHelpers.getTime(offsetMillis) since that will be
    // affected by the the changes to moment.now in subsequent calls to setNow()

    // Official way to change the time source for everything moment related.
    // https://momentjs.com/docs/#/customization/now/
    moment.now = function () {
      const now = new Date();
      return now.getTime() + offsetMillis;
    };
  },

  getTime(offset = 0) {
    return this.createDate().add(offset, 'milliseconds')
      .valueOf();
  },

  createDate(...args) {
    return moment(...Array.from(args || []));
  },

  createUtcDate(...args) {
    return moment.utc(...Array.from(args || []));
  },

  createCurrentTimeZoneDateInUtc(timezone) {
    const timestamp = this.createDate().valueOf();
    return this.getSameTimeZoneTimeInUtc(timestamp, timezone);
  },

  performMomentOperation(op, amount, units, startingDate) {
    if (!(['days', 'hours', 'months', 'years'].includes(units))) {
      throw new Error('You must specify a valid unit type.');
    }

    return moment(startingDate)[op](amount, units)
      .toDate();
  },

  setTimeToMidnight(date) {
    date.hour(23);
    date.minute(59);
  },

  getThirtyDaysFrom(startingDate) {
    return this.performMomentOperation('add', 30, 'days', startingDate);
  },

  getThirtyDaysBefore(startingDate) {
    return this.performMomentOperation('subtract', 30, 'days', startingDate);
  },

  timeFromEvent(timestamp, options = {}) {
    const {
      fallbackDateString = '',
      withSuffix = true
    } = options;

    const offset = options.serverTimeOffset || 0;
    const serverTimeNow = options.serverTimeNow || this.getTime(offset);

    if (!Number.isInteger(timestamp) || !Number.isInteger(serverTimeNow)) {
      return fallbackDateString;
    }

    return this.createDate(timestamp).from(serverTimeNow, !withSuffix);
  }, // negate withSuffix for moment's backwards API

  calculateWeek(timestamp) {
    return this.createDate(timestamp).isoWeek();
  },

  getDaysRemaining(offset, date) {
    const dueDate = this.timestampToISODate(date);
    const serverTimeOffset = this.getTime(offset);
    const serverTimeOffsetAsDate = this.timestampToISODate(serverTimeOffset);

    return this.createDate(dueDate).diff(this.createDate(serverTimeOffsetAsDate), 'days');
  },

  getDaysBetweenFloat(startTime, endTime) {
    return this.createDate(endTime).diff(this.createDate(startTime), 'days', true);
  },

  getTimezoneOffset() {
    return (new Date()).getTimezoneOffset();
  },

  getTimeZoneNameArray() {
    return moment.tz.names();
  },

  getTimeZoneSelectOptions() {
    // Temp change until COMM-17 ticket is finished
    // Overriding the timezone names that are displayed to the user
    const tzDisplayOverrides = {
      Turkey: 'Türkiye'
    };

    return this.getTimeZoneNameArray().map((value) => {
      return {
        id: value,
        value: tzDisplayOverrides[value] || value,
        label: value
      };
    });
  },

  getBrowserTimezone() {
    return moment.tz.guess();
  },

  getSameUtcTimeInTimeZone(timestamp, timezone) {
    const dateStringInUtc = this.convertUtcDateFormatLongDateTimeOnlyNumbers(timestamp);
    return moment.tz(dateStringInUtc, timezone);
  },

  getSameTimeZoneTimeInUtc(timestamp, timezone) {
    const dateStringInTimeZone = this.convertTimeZoneDateFormatLongDateTimeOnlyNumbers(timestamp, timezone);
    return this.createUtcDate(dateStringInTimeZone);
  },

  getDayNamesShort() {
    return moment.weekdaysShort();
  },

  // Example: February (month name returned in user locale)
  getMonthName(date) {
    return this.createDate(date).format('MMMM');
  },

  getMonthNames() {
    return moment.months();
  },

  getMonthNamesShort() {
    return moment.monthsShort();
  },

  // returns bool
  // possible condition: isBefore, isSame, isAfter, isSameOrBefore, isSameOrAfter
  // possible dateUnit: year, month, day, etc..
  compareDates(dateA, condition, dateB, dateUnit) {
    if (!_.contains(['isBefore', 'isSame', 'isAfter', 'isSameOrBefore', 'isSameOrAfter'], condition)) {
      throw new Error(
        'condition should be one of the following: "isBefore", "isSame", "isAfter", "isSameOrBefore", "isSameOrAfter"'
      );
    }

    return moment(dateA)[condition](dateB, dateUnit);
  },

  getDateDifference(dateA, dateB, unit = '', floating = false) {
    return moment(dateA).diff(dateB, unit, floating);
  },

  getStartAndEndOfWeek(date, weekStartDay = 0) {
    let startDate, endDate;

    if (date.day() >= weekStartDay) {
      const dateDifference = date.day() - weekStartDay;
      startDate = date.clone().subtract(dateDifference, 'd');
      endDate = startDate.clone().add(6, 'd');
    } else {
      const dateDifference = weekStartDay - date.day() - 1; // End date needs to be the day before the start of the next week
      endDate = date.clone().add(dateDifference, 'd');
      startDate = endDate.clone().subtract(6, 'd');
    }

    return {
      startDate,
      endDate
    };
  },

  validateDateString(date, dateRequired = true) {
    if (!dateRequired && date === '') {
      return true;
    }

    return moment(date, 'YYYY-MM-DD', true).isValid();
  },

  // returns timezones such as BRT or GMT-03:00 depending on the locale
  getTimeZoneAbbreviationFromIntlDateTimeFormat(browserTimezone, utcOffset = 0) {
    const abbreviationOptions = new Intl.DateTimeFormat([], {
      timeZoneName: 'short',
      timeZone: browserTimezone,
      localeMatcher: 'lookup'
    });
    const formattedTime = abbreviationOptions.format(new Date().setUTCHours(utcOffset));
    const timeZoneParts = formattedTime.toString().split(' ');
    return timeZoneParts[timeZoneParts.length - 1];
  },
  getTimeWithBestTimezoneAbbreviation(date, browserTimezone = 'America/Toronto') {
    if (date == null) {
      return '';
    }
    const momentDateTimeZone = (date)?.tz(browserTimezone)
      .format('LT z')
      .toString();
    const splitDateTimeZone = momentDateTimeZone.split(' ');

    // if the returned abbreviation cannot be translated to a simple int such as -03
    if (parseInt(splitDateTimeZone[splitDateTimeZone.length - 1], 10)) {
      const timezoneAbbreviation = this.getTimeZoneAbbreviationFromIntlDateTimeFormat(browserTimezone);
      const alternativeTimeFormat = `${ (date)?.tz(browserTimezone).format('LT') } ${ timezoneAbbreviation }`;
      return alternativeTimeFormat;
    }

    return momentDateTimeZone;
  }
};

const Marionette = require('Marionette');
const $os = require('detectOS');
const logging = require('logging');

const I18n = require('@common/libs/I18n');

const NativeBridgeHelpers = require('@common/libs/helpers/app/NativeBridgeHelpers');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

const OAuthProviderEnum = require('@common/data/enums/OAuthProviderEnum');
const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');

require('@common/modules/auth/views/login/oauth-icons.less');

class OAuthButtonView extends Marionette.ItemView {
  constructor(options = {}) {
    super(options);

    ({
      loginWithOAuth: this.loginWithOAuth,
      showSpinner: this.showSpinner = () => {},
      hideSpinner: this.hideSpinner = () => {},
      showFlashError: this.showFlashError,
      language: this.language
    } = options);

    this.checkDoubleSubmit = BrowserHelpers.checkDoubleSubmit.bind(this);
  }

  templateHelpers() {
    const oauthProvider = this.model.get('uuid');

    return {
      oauthProvider,
      labelStr: this._getLabelStr(oauthProvider),
      showIcon: OAuthProviderEnum.values().includes(oauthProvider)
    };
  }

  events() {
    return {
      'click button': '_onButtonClick'
    };
  }

  className() {
    return 'oauth-provider';
  }

  getTemplate() {
    return `\
      <button class="axon-button <%- oauthProvider.toLowerCase() %> grid-button axon-button--no-branding" data-provider="<%- oauthProvider %>">
        <% if(showIcon) { %>
        <span class="oauth-icon oauth-icon--<%- oauthProvider.toLowerCase() %>" aria-hidden="true"></span>
        <% } %>
        <span class="oauth-text"><%- labelStr %></span>
      </button>\
    `;
  }

  _getLabelStr(oauthProvider) {
    let labelStr = I18n.t('login.withOAuthProvider', { oauthProvider });
    if (this.model.get('label') != null) {
      const localizableLabel = new LocalizableString(this.model.get('label'));
      labelStr = localizableLabel.getValueForLanguage(this.language);
    }
    return labelStr;
  }

  _onButtonClick(e) {
    e.preventDefault();

    // Since Chrome Custom Tabs has some warmup time there's a higher likelihood
    // of a user tapping on the OAuth button thinking that nothing is happening.
    if (
      NativeBridgeHelpers.isInApp()
      && $os.android
      && this.checkDoubleSubmit(5000)
    ) {
      return false;
    }

    const providerInfo = {
      providerName: this.model.get('uuid'),
      issuer: this.model.get('issuer'),
      clientId: this.model.get('clientId'),
      redirectUrl: this.model.get('redirectUri'),
      discoveryUrl: this.model.get('discoveryUrl'),
      authorizationUrl: this.model.get('authorizationEndpoint'),
      tokenUrl: this.model.get('tokenEndpoint'),
      scopes: this.model.get('scopes'),
      type: this.model.get('type'),
      prompt: this.model.get('prompt')
    };

    logging.info(`Logging in with OAuth provider \`${ providerInfo.providerName }\``);

    // This is one instance where we want to explicitly show the spinner since
    // the request can take a while to be fulfilled.
    this.showSpinner();

    this.loginWithOAuth(providerInfo, this.model.get('loginUri'))
      .always(() => {
        return this.hideSpinner();
      })
      .fail((authState = {}) => {
        const {
          error,
          exception
        } = authState;
        if (error || exception) {
          this.showFlashError(I18n.t('errors.auth.oauthError'));
        }
      });
    return undefined;
  }
}

module.exports = OAuthButtonView;

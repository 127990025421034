const Marionette = require('Marionette');

class SelectGameHowToModalContentView extends Marionette.ItemView {
  getTemplate() {
    return require('@training/apps/training/templates/SelectGameHowToModal.html');
  }

  className() {
    return 'modal__content';
  }
}

module.exports = SelectGameHowToModalContentView;

const Marionette = require('Marionette');

class GuidedLearningExplanationModal extends Marionette.LayoutView {
  className() {
    return 'gl-modal ax-grid';
  }

  getTemplate() {
    return require('@training/apps/training/views/guidedLearning/GuidedLearningExplanationModal.html');
  }

  templateHelpers() {
    return {
      coach: window.apps.auth.session.user.getCoachType()
    };
  }

  regions() {
    return {
      typeList: '.gl-modal__type-list'
    };
  }
}

module.exports = GuidedLearningExplanationModal;

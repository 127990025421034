const logging = require('logging');
const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

const ReferralHowToModal = require('./ReferralHowToModal');
const ReferralActiveInvitesView = require('@training/apps/training/views/ReferralActiveInvitesView');
const ReferralRecentInvitesView = require('@training/apps/training/views/ReferralRecentInvitesView');
const BountyConfiguration = require('../models/BountyConfiguration');

const Referrals = require('../models/Referrals');

class ReferralPage extends UIKit.View {
  get template() {
    return _.tpl(require('../templates/ReferralPage.html'));
  }

  className() {
    return 'tell-a-friend-page';
  }

  events() {
    return { 'click .js-how-to-link': 'displayReferralHowToModal' };
  }

  constructor(...args) {
    super(...args);
    this.bountySelected = this.bountySelected.bind(this);
    this.bountyDeleted = this.bountyDeleted.bind(this);
    this.bountyDeletedError = this.bountyDeletedError.bind(this);
    this.bountySelectionError = this.bountySelectionError.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  initialize() {
    ({
      referralStatus: this.referralStatus,
      onContinue: this.onContinue = _.noop
    } = this.options);

    this.configuration = new BountyConfiguration();
    this.referrals = new Referrals();
    this.referralsPromise = this.referrals.fetch();
    this.configurationPromise = this.configuration.fetch();

    this.listenTo(this.referrals, 'bountySelected', this.bountySelected);
    this.listenTo(this.referrals, 'bountyDeleted', this.bountyDeleted);
    this.listenTo(this.referrals, 'bountySelectionError', this.bountySelectionError);
    this.listenTo(this.referrals, 'bountyDeletedError', this.bountyDeletedError);
  }

  viewDidAppear() {
    logging.info('ReferralPage - viewDidAppear');

    window.app.layout.setTitle(I18n.t('referral.title'));

    // if this page is not being inserted before the Hub then
    // use 'back-hub' if the user navigated there
    if (this.options.showBeforeHub) {
      this.actionBar.setActionButton({
        type: 'continue',
        onClick: this.onContinue
      });
    }

    if (this.shouldDisplayHowToModal()) {
      this.displayReferralHowToModal();
    }
  }

  shouldDisplayHowToModal() {
    const hasSeenReferralHelpText = this.referralStatus.get('hasSeenReferralHelpText');
    if (hasSeenReferralHelpText === false) {
      // set this user's attribute to true for future visits from session
      this.referralStatus.setHasSeenReferralHelpText();
    }

    return !hasSeenReferralHelpText;
  }

  render() {
    // Load view
    this.$el.html(this.template());

    $.when(this.referralsPromise, this.configurationPromise).done(() => {
      // determine which left pane should be displayed
      // the list of available people or the active referral
      // if there's an active bounty, show the active one
      if (this.referrals.get('claimed')) {
        this.renderPendingView();
      } else if (this.referrals.get('available').length > 0) {
        // else if there are some available bounties, show the list of available bounties
        this.renderAvailableView();
      }

      // render the active and recent invite views
      const activeView = new ReferralActiveInvitesView({
        el: this.$('.active-invites-container'),
        configuration: this.configuration,
        collection: this.referrals.getInProgressUsers()
      });
      activeView.render();

      const recentView = new ReferralRecentInvitesView({
        el: this.$('.recent-invites-container'),
        collection: this.referrals.getRecentActivityUsers()
      });
      recentView.render();

      this.triggerAdjustment();
    });
    return this;
  }

  renderPendingView() {
    const LeftPaneView = require('@training/apps/training/views/ReferralPendingView');
    this.$('#left-pane').html('<div></div>');
    this.$('#left-pane > div').hide();

    const v = new LeftPaneView({
      el: this.$('#left-pane div'),
      referrals: this.referrals,
      collection: this.referrals.getPendingUser(),
      pending: this.referrals.get('claimed'),
      configuration: this.configuration
    });
    v.render();
    this.$('#left-pane > div').fadeIn('fast');
  }

  renderAvailableView() {
    const LeftPaneView = require('@training/apps/training/views/ReferralAvailableView');
    this.$('#left-pane').html('<div></div>');
    this.$('#left-pane > div').hide();

    const v = new LeftPaneView({
      el: this.$('#left-pane div'),
      referrals: this.referrals,
      collection: this.referrals.getAvailableUsers(),
      configuration: this.configuration
    });
    v.render();
    this.$('#left-pane > div').fadeIn('fast');
  }

  displayReferralHowToModal() {
    const modalView = new AccessibleModalView({
      className: 'how-to-modal confirm-dialog-view'
    });

    window.app.layout.presentModal(modalView);
    modalView.setSubviewIn(
      new ReferralHowToModal({
        configuration: this.configuration
      }),
      { transition: UIKit.View.Transitions.NONE }
    );
  }

  bountySelected() {
    logging.info('refresh data from selection');
    this.refreshData();
  }

  bountyDeleted() {
    logging.info('refresh data from deletion');
    this.refreshData().then(() => {
      $('.small-invite-buttonbar .js-remove-bounty').first()
        .trigger('focus');
    });
  }

  bountyDeletedError() {
    logging.error('refresh data from deletion error');
    this.refreshData();
  }

  bountySelectionError() {
    logging.info('refresh data from selection error');
    this.refreshData();
  }

  /**
   *
   * You can handle any other error code that would not be handled otherwise here.
   * A bunch of them are handled locally and that is not enough but if it bubbles here, then you can go ahead and assume
   * something is very wrong here.
   */
  handleBountyError(errorCode) {
    // Do nothing for now, but there will be code here
    logging.info(errorCode + ' was thrown for the referrals but we tried to handle it.');
  }

  refreshData() {
    // need to refresh the data
    return this.referrals.fetch({
      success: () => {
        if (this.isDestroyed) {
          return;
        }
        this.render();
      },
      error() {
        logging.error('Failed to update the Tell-A-Friend referrals list');
      }
    });
  }

  onClose(...args) {
    this.referrals.abortXHR(Sync.Method.READ);

    this.actionBar?.setActionButton(null);
    if (typeof this.options.complete === 'function') {
      this.options.complete();
    }

    super.onClose(...args);
  }
}

module.exports = ReferralPage;

const Backbone = require('Backbone');

module.exports = new Backbone.Collection([
  {
    key: 'topic'
  },
  {
    key: 'task'
  },
  {
    key: 'event_enrollment',
    iconKey: 'events'
  },
  {
    key: 'certification',
    iconKey: 'certifications'
  },
  {
    key: 'evaluation'
  },
  {
    key: 'milestone'
  }
]);

import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitTextField(cssVars: CSSStyleDeclaration) {
  return {
    textFieldColorBackground: getCssValue('--axInput-color-background', cssVars),
    textFieldColorBorder: getCssValue('--axInput-color-border', cssVars),
    textFieldColorFont: getCssValue('--axInput-color-font', cssVars),
    textFieldColorPlaceholder: getCssValue('--axInput-color-placeholder', cssVars),
    textFieldLineHeight: getCssValue('--axInput-line-height', cssVars),
    textFieldPaddingYL: getCssValue('--axInput-padding-y-l', cssVars),
    textFieldPaddingYM: getCssValue('--axInput-padding-y-m', cssVars),
    textFieldRadius: getCssValue('--axInput-radius', cssVars),
    textFieldWidthBorder: getCssValue('--axInput-width-border', cssVars),
    textFieldWidthMin: getCssValue('--axInput-width-min', cssVars),
    textFieldDisabledColorBackground: getCssValue('--axInput-disabled-color-background', cssVars),
    textFieldDisabledColorBorder: getCssValue('--axInput-disabled-color-border', cssVars)
  };
}

const $os = require('detectOS');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');

const SelectGameHowToModalContentView = require('@training/apps/training/views/SelectGameHowToModalContentView');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

const { createButtonWithCustomText } = require('@common/components/actionBarButton/ActionBarButtonFactory');

let modalGuard = false; // to avoid multiple clicks on tooltip on desktop

const displayModal = (layoutView) => {
  if (layoutView == null || modalGuard) {
    return;
  }

  modalGuard = true;

  const modalView = new SelectGameHowToModal();

  if ($os.mobile) {
    layoutView.presentModal(modalView, {
      onLoad: () => {
        layoutView.togglePageHeader(false);
      },
      onClose: () => {
        layoutView.togglePageHeader(true);
        modalGuard = false;
      }
    });
  } else {
    layoutView.presentModal(modalView, {
      onClose: () => {
        modalGuard = false;
      }
    });
  }

  modalView.setSubviewIn(new SelectGameHowToModalContentView(), { transition: UIKit.View.Transitions.NONE });
};

class SelectGameHowToModal extends AccessibleModalView {
  get className() {
    return 'how-to-modal how-to-modal__select-game';
  }

  onRenderActionBar() {
    const gotItButtonConfig = createButtonWithCustomText(I18n.t('selectGame.gameChallenge.howTo.accept'), () => {
      this.dismiss();
    });

    this.actionBar.setActionBar({
      buttons: [
        Object.assign(gotItButtonConfig, {
          className: 'got-it'
        })
      ]
    });
  }
}

module.exports = {
  SelectGameHowToModal,
  displayModal
};
